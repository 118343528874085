/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-fragments */
/* eslint-disable indent */
import React from 'react'
import styled from '@emotion/styled'
import { StaticQuery, graphql } from 'gatsby'

import idx from 'idx'

import mapValues from 'lodash/mapValues'
import groupBy from 'lodash/groupBy'
import omit from 'lodash/omit'

import whiteCompanyLogo from '../../static/logos/company-logo-green.svg'
import Text from './common/Text'
import Space from './common/Space'
import SocialLinks from './common/SocialLinks'
import TextLink from './common/TextLink'
import Accordion from './common/Accordion'
import { theme } from '../styles'

import { useUserLang } from '../context/UserLangContext'
import generateFooterTranslations from '../data/generateFooterTranslations'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0px 80px 60px 80px;
  background-color: ${theme.color.offBlack};
  color: white;
  ${props =>
    props.isDai && props.hideTableOfContents
      ? 'width: 100%;'
      : props.isDai && !props.hideTableOfContents
      ? 'width: 100%;'
      : 'width: 100vw;'};
  margin-left: ${props =>
    props.isDai && props.hideTableOfContents ? '0px' : props.isDai && !props.hideTableOfContents ? '350px' : '-150px'};

  @media (max-width: 1199px) {
    ${props => (props.isDai ? 'margin-left: 0px;' : 'margin-left: -150px;')};
    ${props =>
      props.isDai && props.hideTableOfContents
        ? 'width: 100%;'
        : props.isDai && !props.hideTableOfContents
        ? ''
        : 'width: 100vw;'};
  }

  @media (max-width: 1050px) {
    padding: 40px 24px 60px 24px;
    width: 100vw;
    margin-left: ${props => (props.isDai ? '0px' : '-24px;')};
    justify-content: center;
    justify-items: center;
  }

  @media (max-width: 800px) {
    padding: 40px 14px 60px 14px;
  }
`

const Desktop = styled.div`
  @media (max-width: 999px) {
    display: none;
  }
`

const Mobile = styled.div`
  @media (min-width: 1000px) {
    display: none;
  }
`

const Col = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: 1050px) {
    min-width: 49%;
    margin-bottom: 24px;
  }
`

const SectionWrapper = styled.div`
  display: flex;
  padding: 68px 0px;
  width: 100%;
  column-gap: 16px;

  @media (max-width: 1050px) {
    flex-direction: column;
    padding: 40px 0px;
  }
`

const Content = styled(SectionWrapper)`
  border-bottom: 1px solid ${theme.color.gray400};

  @media (max-width: 1050px) {
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 16px;
  }
`

const BottomWrapper = styled(SectionWrapper)`
  column-gap: 0px;
  @media (max-width: 1050px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`

const BottomLinkWrapper = styled.div`
  flex: 4;
  display: flex;

  .social-links li:first-of-type {
    margin-left: 16px;

    @media (max-width: 580px) {
      margin-left: 0;
    }
  }
`

const FooterLinkList = styled.ul`
  li {
    margin-bottom: 0;
    font-size: initial;
    list-style-type: none;

    a {
      text-decoration: none;
      z-index: 0;
      &:focus,
      &:hover {
        z-index: 1;
      }
    }
  }
`

const FooterTextLinkWrapper = styled.ul`
  flex: 3;
  display: flex;
  column-gap: 16px;

  li {
    flex: 1;
    margin-bottom: 0;
    list-style-type: none;

    a {
      text-decoration: none;
    }
  }

  &.hide-desktop {
    display: none;
    @media (max-width: 1050px) {
      display: flex;
      padding-bottom: 40px;
    }

    @media (max-width: 580px) {
      flex-direction: column;

      > li {
        margin-bottom: 16px;
      }
    }
  }
  &.hide-mobile {
    display: flex;
    @media (max-width: 1050px) {
      display: none;
    }
  }
`

const FooterLogo = styled.img`
  width: 180px;

  @media (max-width: 1050px) {
    width: 130px;
    margin-top: 0px;
  }
`

const Copyright = styled.div`
  display: flex;
  justify-content: center;

  p {
    display: inline-block;
  }
`

const FooterTextLink = props => (
  <TextLink
    className="dark-bg-link"
    anchorClassName="dark-bg-link dark-bg-focus"
    font="smallBody"
    inFooter
    outboundFooterLink={idx(props.to, to => to.includes('http'))}
    style={{ flex: '1' }}
    {...props}
  />
)

const FooterUtilityLinks = ({ responsiveClass, languageData }) => (
  <FooterTextLinkWrapper className={responsiveClass}>
    <li className="link-list-item">
      <FooterTextLink
        text={languageData?.termsOfServiceLinkText}
        data-cy="footer-terms-of-service"
        to={languageData?.termsOfServiceLink}
      />
    </li>
    <li className="link-list-item">
      <FooterTextLink
        text={languageData?.privacyPolicyLinkText}
        data-cy="footer-privacy-policy"
        to={languageData?.privacyPolicyLink}
      />
    </li>
    <li className="link-list-item">
      <FooterTextLink
        text={languageData?.trustCenterLinkText}
        data-cy="footer-trust-center"
        to="https://trust.audioeye.com/"
      />
    </li>
    <li className="link-list-item">
      <FooterTextLink
        text={languageData?.cookieSettingsLinkText}
        data-cy="footer-cookie-settings"
        className="cky-banner-element dark-bg-link"
        to="#"
        noSmoothScroll
      />
    </li>
  </FooterTextLinkWrapper>
)

const StyledText = styled(Text)`
  z-index: 0;
`

const Footer = ({ hideHeaderFooterNav, isDai, hideTableOfContents, microSiteHeaderFooter }) => {
  const footerQuery = graphql`
    query Footer {
      prismicFooterLinks {
        data {
          group {
            title {
              text
            }
            section
            link_fallback {
              text
            }
            link {
              link_type
              uid
              url
            }
          }
        }
      }
    }
  `
  const { userLang } = useUserLang()
  const languageData = generateFooterTranslations({ userLang })

  return (
    <Wrapper role="contentinfo" className="footer" isDai={isDai} hideTableOfContents={hideTableOfContents}>
      {!hideHeaderFooterNav && !microSiteHeaderFooter && (
        <Content>
          <StaticQuery
            query={`${footerQuery}`}
            render={data => {
              const items = data.prismicFooterLinks.data.group

              const grouped = mapValues(groupBy(items, 'section'), clist => clist.map(car => omit(car, 'section')))

              return Object.keys(grouped).map((key, indexOut) => (
                // eslint-disable-next-line react/no-array-index-key
                <React.Fragment key={`${indexOut}:${key}`}>
                  <Col>
                    <Desktop>
                      <Text role="heading" aria-level="2" bodyBold color="white">
                        {key}
                      </Text>
                      <Space height={15} />
                      <FooterLinkList>
                        {grouped[key].map((item, indexIn) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <React.Fragment key={`${indexIn}:${item.link && item.link.url}`}>
                            <li className="link-list-item">
                              <FooterTextLink
                                text={item.title.text}
                                data-cy={`footer-${item.title.text.replace(/ /g, '-').toLowerCase()}`}
                                to={
                                  (item.link && item.link.url) ||
                                  (item.link_fallback && item.link_fallback && item.link_fallback.text)
                                }
                              />
                            </li>
                            <Space height={8} />
                          </React.Fragment>
                        ))}
                      </FooterLinkList>
                    </Desktop>
                    <Mobile>
                      <Accordion title={key} index={indexOut}>
                        <FooterLinkList>
                          {grouped[key].map((item, indexIn) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <React.Fragment key={`${indexIn}:${item.link && item.link.url}`}>
                              <li className="link-list-item">
                                <FooterTextLink
                                  text={item.title.text}
                                  data-cy={`footer-${item.title.text.replace(/ /g, '-').toLowerCase()}`}
                                  to={
                                    (item.link && item.link.url) ||
                                    (item.link_fallback && item.link_fallback && item.link_fallback.text)
                                  }
                                />
                              </li>
                              <Space height={8} />
                            </React.Fragment>
                          ))}
                        </FooterLinkList>
                      </Accordion>
                    </Mobile>
                  </Col>
                </React.Fragment>
              ))
            }}
          />
        </Content>
      )}
      <BottomWrapper>
        <Col>
          <FooterLogo src={whiteCompanyLogo} alt="AudioEye logo" />
        </Col>
        <BottomLinkWrapper>
          <FooterUtilityLinks responsiveClass="hide-mobile" languageData={languageData} />
          <SocialLinks color="white" />
        </BottomLinkWrapper>
      </BottomWrapper>
      <FooterUtilityLinks responsiveClass="hide-desktop" languageData={languageData} />
      <Copyright>
        <StyledText center color="gray400" tinyBody>
          {languageData?.disclaimer}
        </StyledText>
      </Copyright>
    </Wrapper>
  )
}

export default Footer
